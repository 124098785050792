body {
    background-color: #000;
    color: #090;
    font-family: monospace;
}

pre {
    margin: 0;
    display: inline;
}

.error {
    color: #900;
}

.read {
    color: #090;
}

.write {
    color: #0f0;
}

.cursor {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {  
    50% { opacity: 0; }
  }

